/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Spin, message } from "antd";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import LoginForm from "./form";
import Content from "../../components/Content";
import { validationSchema } from "./constant";
import BG from "../../assets/Bitmap.png";
import { LOGIN_USER } from "./graphql";
import { useEffect } from "react";
import { IFormikInitialValues, ILoginData } from "./types";
import ContractView from "@/components/ContractViewer/ContractViewer";

const Login: React.FC = () => {
    const history = useHistory();
    const [loader, setLoader] = useState<boolean>(false);
    const [errors, setErrors] = useState<string>("");
    const initialValues: IFormikInitialValues = {
        companyCode: "",
        contractNo: "",
    };
    const [login] = useMutation(LOGIN_USER);
    const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false);
    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false);
    const [isShowErrorTermsAndCondition, setIsShowErrorTermsAndCondition] =
        useState<boolean>(false);
    const [isShowErrorRecaptcha, setIsShowErrorRecaptcha] = useState<boolean>(false);
    const [recaptcha, setRecaptcha] = useState<string | null>("");

    const resetForm = (actions: any): void => {
        setIsAgreeChecked(false);
        setIsRecaptchaChecked(false);
        setRecaptcha(null);
        actions.resetForm({
            values: {
                companyCode: "",
                contractNo: "",
            },
        });
    };

    const handleSubmit = async (values, actions) => {
        if (!isAgreeChecked) {
            setIsShowErrorTermsAndCondition(true);
        }

        if (!isRecaptchaChecked) {
            setIsShowErrorRecaptcha(true);
        }

        if (!isAgreeChecked || !isRecaptchaChecked) {
            return;
        }

        setIsShowErrorTermsAndCondition(false);
        setIsShowErrorRecaptcha(false);
        setLoader(true);

        const data: ILoginData = {
            companyCode: values.companyCode,
            contractNo: values.contractNo,
            brand: "ALVEO",
            recaptchaToken: recaptcha || "",
        };

        try {
            const mdorEmployeeLoginResponse = await login({
                variables: { data },
            });

            if (mdorEmployeeLoginResponse.data.mdorEmployeeLogin.document.mdor_document === null) {
                setErrors("No uploaded document yet");
                resetForm(actions);
            } else {
                const {
                    data: {
                        mdorEmployeeLogin: { document, token },
                    },
                } = mdorEmployeeLoginResponse;

                message.success("Login success.");
                localStorage.setItem(
                    "ACCESS_TOKEN",
                    JSON.stringify(document.mdor_document.document_custom_id)
                );
                localStorage.setItem("MDOR_FILE", JSON.stringify(document.mdor_document.file));
                localStorage.setItem("MDOR_FILE_TOKEN", JSON.stringify(token));
                history.push("/dashboard");
            }
        } catch (error) {
            setErrors("Invalid credential. Please contact Customer Service");
            resetForm(actions);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("MDOR_FILE");
        localStorage.removeItem("MDOR_FILE_TOKEN");
    }, []);

    if (typeof window !== "undefined" && !!window.location.search) {
        return (
            <Spin spinning={loader}>
                <Content bgImg={BG}>
                    <ContractView />
                </Content>
            </Spin>
        );
    }

    return (
        <Spin spinning={loader}>
            <Content bgImg={BG}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values: any, actions: any) => handleSubmit(values, actions)}
                    render={(formikBag) => (
                        <LoginForm
                            {...{
                                formikBag,
                                errors,
                                isAgreeChecked,
                                setIsAgreeChecked,
                                isRecaptchaChecked,
                                setIsRecaptchaChecked,
                                isShowErrorTermsAndCondition,
                                setIsShowErrorTermsAndCondition,
                                isShowErrorRecaptcha,
                                setIsShowErrorRecaptcha,
                                recaptcha,
                                setRecaptcha,
                            }}
                        />
                    )}
                />
            </Content>
        </Spin>
    );
};
export default Login;
