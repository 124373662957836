import styled from "styled-components";

export const Container = styled.div`
    background-color: #eee;
    padding: 2rem 6rem;

    .react-pdf__Document {
        display: grid;
        place-items: center;
    }

    &__canvas {
        pointer-events: none;
    }

    &__pager {
        display: inline-flex;
        align-items: center;
        background: #fff;
        border-radius: 8px;
        width: auto;
        padding: 0 10px;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

        p {
            margin: 10px 15px;
            color: #a5a5a5;
            font-size: 12px;
        }
    }

    &__pager-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }

        &:focus {
            outline: none;
        }
    }

    &__loading {
        color: #990d15;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const ButtonsContainer = styled.div`
    position: absolute;
    top: 2%;
    right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .mdor-viewer__btn-container__btn--download,
    .mdor-viewer__btn-container__btn--in,
    .mdor-viewer__btn-container__btn--out {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border: none;
        font-size: 24px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        text-align: center;
        cursor: pointer;

        &:disabled {
            background-color: #bbbbbb;
        }

        &:focus {
            outline: none;
        }

        &--in {
            top: 15px;
        }

        &--out {
            top: 65px;
        }

        &--down {
            top: 115px;
            display: grid;
            place-items: center;
            font-size: 15px;
            color: #000;
        }
    }
`;
