import React, { ReactElement, useState } from "react";
import { Container, ButtonsContainer } from "./styled";
import { Document, Page, pdfjs } from "react-pdf";
import { DownloadOutlined } from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfFile }: any): ReactElement => {
    const [numPages, setNumPages] = useState<number>(0);
    const [zoom, setZoom] = useState<number>(1);

    const contractFile = {
        url: pdfFile,
    };

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    return (
        <Container className="mdor-viewer">
            <Document file={contractFile} onLoadSuccess={onDocumentLoadSuccess}>
                {[...Array(numPages).keys()].map((page) => (
                    <Page key={page} pageNumber={page + 1} scale={zoom} renderTextLayer={false} />
                ))}
            </Document>
            <ButtonsContainer className="mdor-viewer__btn-container">
                <a
                    href={pdfFile}
                    className="mdor-viewer__btn-container__btn--download"
                    rel="noreferrer"
                    download
                >
                    <DownloadOutlined />
                </a>
                <button
                    type="button"
                    className="mdor-viewer__btn-container__btn--in"
                    onClick={(): void => setZoom(zoom + 0.25)}
                >
                    &#x2b;
                </button>
                <button
                    type="button"
                    className="mdor-viewer__btn-container__btn--out"
                    onClick={(): void => setZoom(zoom > 1 ? zoom - 0.25 : 1)}
                    disabled={zoom === 1}
                >
                    &#x2013;
                </button>
            </ButtonsContainer>
        </Container>
    );
};

export default PDFViewer;
