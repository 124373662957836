/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactElement, useEffect, useState } from "react";
import axios from "axios";
import PDFViewer from "./component/PDFViewer";
import getContract from "../../services/getContract";

const ContractView = (): ReactElement => {
    const [contractFile, setContractFile] = useState<string>("");

    let version: string;
    let usageType: string;
    let type: string;
    let fileAlias: string;

    // ? TO VERIFY CONTENT API ENDPOINT
    const API_URL = `${process.env.REACT_APP_CONTENT_URL?.replace("/graphql", "")}`;

    if (typeof window !== `undefined`) {
        const myKeys = window.location.search;
        const urlParams = new URLSearchParams(myKeys);
        version = urlParams.get("v")!;
        fileAlias = urlParams.get("fa")!;
        usageType = urlParams.get("ut")!;

        if (usageType.includes("House")) {
            usageType = "House & Lot";
        }

        type = urlParams.get("dt")!;
    }
    const getFile = async (fileLink: string): Promise<any> => {
        const file = await axios.get(`${API_URL}/${fileLink}`);
        return file;
    };

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            // const result = null;
            const result: any = await getContract({
                version,
                fileAlias,
                usageType,
                type,
            });
            if (result) {
                getFile(result).then((res: any) => {
                    setContractFile(res.config.url);
                });
            }
        };
        fetchData();
    }, []);

    if (contractFile) {
        return <PDFViewer pdfFile={contractFile} />;
    }
    return <div className="mdor" />;
};

export default ContractView;
